
.form-underline {
  
  .address-row {
    .form-group {
      min-height: 120px;  
    }
  }
  
  .form-group {
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;

    > div::after {
      content: '\a0';
    }
  }
  
  .flex-hbox, .flex-vbox {
    gap: 15px 30px;
    margin-bottom: 15px;
  }
}
