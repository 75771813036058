
.form-group {
  margin-bottom: 8px;
}

.form-control.ng-invalid {
  border: 1px solid red;
}

.ng-invalid {
  >.ng-select {
    >.ng-select-container {
      border: 1px solid red;
    }
  }
}

.form-gap {
  .flex-hbox, .flex-vbox {
    gap: 0 15px;
  }
}
