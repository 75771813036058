

modal-container:nth-of-type(2) {
  background: rgba(0, 0, 0, 0.5);
}

@media (min-width: 992px) {
  .modal-xl {
    width: 1100px;
  }  
}

@media (max-width: 991px) {
  .modal-xl {
    width: 100%;
  }
}
