/* https://github.com/Munawwar/flex-helper */

/*Stack child items vertically*/
.flex-vbox {
  display: flex;

  /*Align children vetically*/
  flex-direction: column;
  align-content: flex-start;

  //overflow: hidden; /*Prevent extending beyond boundaries*/
}
/*Stack child items horizontally*/
.flex-hbox {
  display: flex;

  /*Align children horizontally*/
  flex-direction: row;
  //align-content: stretch;

  /*Wrap items to next line on main-axis*/
  flex-wrap: wrap;
  
  &.no-wrap {
    flex-wrap: nowrap;
  }

  //overflow: hidden; /*Prevent extending beyond boundaries*/
}

.flex-gap {
  gap: 5px;
}

.flex-gap-lg {
  gap: 15px;
}

/*Stretch item along parent's main-axis*/
.flex, .flex-1 {
  flex: 1;
  flex-basis: 0;
  flex-grow: 1;
}

.flex-2 {
  flex: 2;
  flex-basis: 0;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

/*Prevent extending beyond boundaries*/
.flex-scroll {
  overflow-y: auto; /*Prevent extending beyond boundaries*/
}

/*Stretch item along parent's cross-axis Overrides any cross-* class of parent*/
.flex-stretch-self {
  align-self: stretch;
}
/*Center item along parent's cross-axis. Overrides any cross-* class of parent*/
.flex-center-self {
  align-self: center;
}
/*Stack self to the main-axis end.*/
.flex-end-self {
  align-self: flex-end;
}

/*Stack child items to the main-axis start*/
.flex-main-start {
  justify-content: flex-start;
}
/*Stack child items to the cross-axis start*/
.flex-cross-start {
  align-items: flex-start;
  align-content: flex-start;
}
/*Stack child items to the main-axis center*/
.flex-main-center {
  justify-content: center;
}
/*Stack child items to the cross-axis center*/
.flex-cross-center {
  align-items: center;
  align-content: center;
}
/*Stack child items to the main-axis end.*/
.flex-main-end {
  justify-content: flex-end;
}
/*Stack child items to the cross-axis end.*/
.flex-cross-end {
  align-items: flex-end;
  align-content: flex-end;
}
/*Stretch child items along the cross-axis*/
.flex-cross-stretch {
  align-items: stretch;
  align-content: stretch;
}


.flex-tabset {
  display: flex;
  flex-direction: column;
  flex: 1;
  
  .nav-tabs {
    a.active {
      background-color: #f9f9f9 !important;
    }
  }
  
  .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    tab {
      
      display: none;
      
      &.active {
        display: flex;
      }
      
      flex-direction: column;
      flex: 1;
      
      border: 1px solid #ddd;
      padding: 5px;
      border-top-width: 0;
      background-color: #f9f9f9;
      
      canvas {
        background-color: #fff;
      }
    }
  }
}