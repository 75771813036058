$ag-grid-size: 3.5px;
$ag-font-size: 13px;
$ag-font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

$grid-size: 3.5px;
$font-size: 13px;
$font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/legacy/ag-theme-balham-v22-compat.scss";

$nocontact-flag-color: #a94442;
$deleted-entitlement: #a94442;
$deleted-entitlement-background-color: #f2dede;
$duplicate-account-error: #cb5906;
$nocontact-background-color: #f2dede;
$nocontact-readonly-background-color: #ffe0c3;
$non-public-event-background-color: lightgrey;
$non-public-event-flag-color: darkgrey;
$nocontact-readyonly-flag-color: darken($nocontact-readonly-background-color, 50%);
$unmatched-attendee: #cb5906;
$ignored-attendee: #800080;

.ag-row, .ag-header-row {
  border-left: 5px solid #ddd !important;
  cursor: pointer;

  @for $i from 1 to 20 {
    .ag-row-group-indent-#{$i} {
      padding-left: $i * 15px !important;
    }
  }

  .ag-row-group-leaf-indent {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  &.non-public-event:not(.ag-row-selected) {
    background-color: $non-public-event-background-color !important;
    border-left-color: $non-public-event-flag-color !important;
  }

  &.do-not-contact {
    border-left-color: $nocontact-flag-color !important;
  }

  &.do-not-contact:not(.ag-row-selected) {
    background-color: $nocontact-background-color;
  }

  &.do-not-contact-readonly {
    border-left-color: $nocontact-readyonly-flag-color !important;
  }

  &.do-not-contact-readonly:not(.ag-row-selected) {
    background-color: $nocontact-readonly-background-color;
  }

  &.duplicate-account-error {
    border-left-color: $duplicate-account-error !important;
    background-color: lighten($duplicate-account-error, 50%) !important;
    font-weight: bold;
  }

  &.moved-contact {
    border-left-color: #f6b237 !important;
  }

  &.deleted-contact {
    border-left-color: #47619c !important;
  }

  &.subscribed-contact {
    border-left-color: #4c4d4c !important;
  }

  &.unmatched-attendee {
    border-left-color: $unmatched-attendee !important;
  }

  &.ignored-attendee {
    border-left-color: $ignored-attendee !important;
  }

  &.pending-delete {
    border-left-color: $deleted-entitlement !important;
    background-color: $deleted-entitlement-background-color;
  }

  .header-right {
    justify-content: flex-end;
    .ag-header-cell-label {
      justify-content: flex-end;
    }
  }

  .header-center {
    .ag-header-cell-label {
      justify-content: center;
    }
    .ag-header-group-cell-label {
      justify-content: center;
    }
  }

  &.groupable-row-grey {
    &:hover {
      .ag-cell {
        background-color: #f5f5f5;
      }
    }

    &.ag-row-level-0 {
      .ag-cell {
        background-color: #f5f5f5;
      }

      &:hover {
        .ag-cell {
          background-color: #e8e8e8;
        }
      }
    }
  }

  &.groupable-row-darken {
    &:hover {
      .ag-cell {
        filter: brightness(85%);
      }
    }

    &.ag-row-level-0 {
      .ag-cell {
        filter: brightness(90%);
        font-weight: bold;
      }

      &:hover {
        .ag-cell {
          filter: brightness(85%);
        }
      }
    }
  }

  &.groupable-row {
    &.ag-row-level-0 {
      .ag-cell {
        background-color: #d9edf7;

        &.lighter {
          background-color: #e9f8ff;
        }
      }

      &:hover {
        .ag-cell {
          background-color: #c4e3f3;
        }
      }
    }

    &.ag-row-level-1.ag-row-group {
      .ag-cell {
        background-color: #e4e6bc;

        &.lighter {
          background-color: #f9fadc;
        }
      }

      &:hover {
        .ag-cell {
          background-color: #e4e6bc;
        }
      }
    }

    .ag-cell {
      background-color: #dff0d8;

      &.lighter {
        background-color: #e9fae2;
      }

      &.no-color {
        background-color: #f5f5f5;
      }
    }

    &:hover {
      .ag-cell {
        background-color: #d0e9c6;
      }
    }

    .ag-cell {
      &.info {
        background-color: #d9edf7 !important;
      }
      &.no-color {
        background-color: #FFF !important;
      }
    }

    &:hover {
      .ag-cell {
        &.no-color {
          background-color: #F5F5F5;
        }
      }

      &:not(.ag-row-pinned) {
        .ag-cell {
          &.info {
            background-color: #c4e3f3;
          }
        }
      }
    }

    &.ag-row-pinned {
      .ag-cell {
        background-color: #F5F5F5 !important;
        font-weight: normal;
      }
    }
  }
}

.non-clickable-row {
  cursor: default;
}

.ag-cell {
  &.bluematrix-yes {
    text-align: center;
    background-color: #288a28;
    color: #fff;
  }
  &.bluematrix-no{
    text-align: center;
  }
  &.marketing-emails-yes {
    text-align: center;
    background-color: #288a28;
    color: #fff;
  }
  &.marketing-emails-no{
    text-align: center;
  }
  &.research-emails-yes {
    text-align: center;
    background-color: #288a28;
    color: #fff;
  }
  &.research-emails-no{
    text-align: center;
  }
  &.interest-category-no{
    text-align: center;
    background-color: #fce45c;
  }
  &.interest-category-not-no{
    text-align: center;
  }
}

.ag-theme-balham {
  .ag-header-row {
    font-size: 14px;
  }

  .ag-row-odd {
    &:not(.ag-row-selected):not(.do-not-contact):not(.do-not-contact-readonly):not(.pending-delete) {
      background-color: #fff;

      &.ag-row-hover {
          background-color: #ECF0F1;
      }
    }
  }

  .ag-status-bar {
    display: none;
  }

  .ag-side-button {

    .ag-side-button-button {
      color: #fff;
      background-color: #47619c;
    }

    .ag-icon {
      color: #fff;
    }
  }

  .ag-root-wrapper {
    .ag-root-wrapper-body {
      .ag-root {
        .ag-body-viewport {
          .ag-center-cols-clipper {
            min-height: 0;
          }
        }
      }
    }
  }

  .ag-details-row {
    padding: 5px;
  }
}

.ag-cell-ng-select {
  overflow: visible !important;
  .ng-select {
    .ng-select-container {
      min-height: 22px !important;
      max-height: 22px;
    }
  }
}

.hide-header .ag-header-cell-text {
  opacity: 0;
}

.ag-header-cell-text {
  color: #333;
  font-weight: 700;
}

.ag-theme-balham .ag-icon {
  color: #333;
  font-weight: 700;
}

.ag-paging-page-summary-panel .ag-paging-button {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 2px;
}

.ag-floating-top-viewport {
  background-color: #f5f7f7;
  font-weight: bold;
}

.ag-numeric-header .ag-header-cell-label {
  flex-direction: inherit;
}

ag-collapsible-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .ag-cell-label-container {
    width: auto;
    flex-grow: 1;
    flex-direction: column;

    .ag-header-cell-label {
      height: 100%;
      align-self: flex-start;
    }
  }

  .collapse-icon {
    height: 100%;
    display: flex;

    i {
      font-size: 12px;
      font-weight: bold;
      color: #000;
      margin-top: 1px;
      align-self: center;
    }
  }
}

.ag-body-horizontal-scroll-viewport[style*='height: 0'] {
  display: none;
}

.boldHeader {
  font-size: 12pt;
  font-weight: bold;
  color: rgb(0, 0, 0);
  padding-left: 1px; // duration icon is chopped without this
}

.ag-header-group-cell {
  color: black;
  font-weight: bold;
}
