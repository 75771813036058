﻿.btn-toggle {
  background-color: #fff;
  border-color: #ccc;
  color: #000;

  &:hover:not(.active) {
    background-color: #efefef;
    color: black;
  }
}
.btn-toggle:hover,
.btn-toggle:active,
.btn-toggle.active {
  background-color: #47619c;
  border-color: #ccc;
  color: #fff;
}
.btn-toggle.disabled:hover,
.btn-toggle.disabled:focus,
.btn-toggle.disabled:active,
.btn-toggle.disabled.active,
.btn-toggle[disabled]:hover,
.btn-toggle[disabled]:focus,
.btn-toggle[disabled]:active,
.btn-toggle[disabled].active,
fieldset[disabled] .btn-toggle:hover,
fieldset[disabled] .btn-toggle:focus,
fieldset[disabled] .btn-toggle:active,
fieldset[disabled] .btn-toggle.active {
  background-color: #4f6cae;
  border-color: #ccc;
  color: #fff;
  font-weight: 500;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}
