.fc-event-main {
  cursor: pointer;
}
.fc-col-header-cell-cushion {
  color: black;
}
.fc-daygrid-day-number {
  color: black;
}
.fc-scroller {
  overflow: auto !important
}