
.dropdown-menu {
  > li {
    > a {
      cursor: pointer;
    }
  }
}

.tab-pane {
  padding-top: 5px;
}