@import "./ckeditor/plugins/placeholders.scss";
@import "./flex.scss";
@import "./ng-select-theme.scss";
@import "bootstrap-css-only/css/bootstrap.min.css";
@import "font-awesome/css/font-awesome.min.css";
@import 'ngx-toastr/toastr';
@import "bootstrap-daterangepicker/daterangepicker.css";
@import "spinkit/css/spinkit.css";
@import "./full-calendar.scss";
@import "./forms.scss";
@import "./modal.scss";
@import "./form-underline";

@import "./aggrid.scss";
@import "./buttons.scss";
@import "./ngx-bootstrap.scss";
@import 'ckeditor5/dist/ckeditor5.css';

$muted-accent-color: #aeaeae;
$divider-color: #d5d5d5;

@-ms-viewport {
    width: auto !important;
}

@-moz-viewport {
    width: auto !important;
}

@-webkit-viewport {
    width: auto !important;
}

@viewport {
    width: auto !important;
}

.page-content {
    position: absolute;
    top: 40px;
    left: 70px;
    height: calc(100vh - 40px);
    width: calc(100% - 70px);
    min-width: calc(1024px - 70px);

    &.no-scroll {
        overflow: hidden;
    }

    > .page-body {
        padding: 5px;
    }
}

.page-banner {
    padding: 15px;
}

.form-control::-ms-clear {
    display: none;
}

.window {
    margin: 5px;

    .window-body {
        border: 1px solid #d5d5d5;
        background-color: #f9f9f9;
    }

    .window-body {
        border-top: 0;
    }

    > .window-body:not(:first-child) {
        border-top: 0;
    }

    > .window-body {
        border-top: 1px solid #d5d5d5;
    }

    .window-header, .window-footer {
        > div {
            > ul {
                list-style: none;
                padding: 0;
                margin: 0;
                line-height: 30px;
            }

            font-size: 14px;
            line-height: 28px;
            padding: 0 10px;
        }

        border: 1px solid #d5d5d5;
        background-color: #fdfdfd;
    }

    .window-footer {
        height: 30px;
        border-top: 0;
    }

    .window-header {
        height: 30px;
        border-bottom: 1px solid #d5d5d5;
    }

    .window-header {
        &.window-header-tabbed {
            > div {
                padding: 0;
                > ul {
                    > li {
                        height: 30px;
                        padding: 0 10px;
                    }

                    > li.active, li:hover {
                        background-color: #f9f9f9;
                        cursor: pointer;
                    }

                    > li.active {
                        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.125);
                    }

                    li:hover:not(.active) {
                        height: 28px; /* This is a weird hack for the borders */
                    }
                }
            }
        }
    }
}
.window-component {
    margin: 5px;
}

.window-content {
    background-color: white;
    margin: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #ddd;
}

.window-header-button {
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 14px;
    vertical-align: super;
    margin-bottom: 5px;
    font-weight: normal;
    display: inline;
}

tbody {
    font-size: 13px;
}

.hide-caret {
    .caret {
        display: none;
    }
}

.multiselect-icon {
    height: 25px;
    margin-top: 1px;
}

.banner {
    background-color: whitesmoke;
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
}

.table-container {
    background-color: white;
}

.table-wrap {
    margin: 5px;
    border: 1px solid #ddd;
}

.justify-center {
    display: flex;
    flex: 1 0 0;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.btn.active, .btn:active {
    border-bottom-color: lightslategrey;
}

.list-btn-filter {
    padding: 1px 6.5px 1px 6.5px;
    font-size: 14px;
    margin-bottom: 4px;
    background-color: inherit;
}

.list-btn-nonfilter {
    padding: 5px 6.5px 3px 6.5px;
    font-size: 14px;
    height: 25px;
    margin-bottom: 4px;
    background-color: inherit;
}

.list-filter-item {
    padding-right: 5px;
    display: inline-block;
    float: right;
    height: 25px;
}

.list-btn-group-right {
    float: right;
    border-left: 1px solid #ddd;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
}

.list-btn-group-left {
    float: left;
    border-right: 1px solid #ddd;
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
}

.list-header-title {
    float: left;
    padding-right: 10px;
    border-right: 1px solid #ddd;
    display: inline-block;
}

.list-paging-input-box {
    display: inline;
    width: 40px;
    padding: 0;
    height: 23px;
    text-align: center;
}

.list-paging-btn-arrows {
    padding: 4px 6.5px 3px 6.5px;
    margin-bottom: 4px;
    background-color: inherit;
}

html {
    overflow: auto;
}

body {
    background: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: relative;
    min-height: 100vh;
}

.header {
    border-bottom: 1px solid $divider-color;
}

.list-heading {
    border-bottom: 1px solid $divider-color;
    margin-top: -15px;
    padding: 20px 10px 5px 10px;
    background-color: #f3f3f3;
}

.list-heading2 {
    margin-top: 0;
    padding: 20px 10px 5px 10px;
}

.list-heading-contact-details {
    margin-top: 0;
    padding: 15px 10px 10px 10px;
}

.list-heading-badge {
    width: 50px;
    height: 50px;
    vertical-align: central;
    text-align: center;
    line-height: 50px;
    border-radius: 3px;
    color: white;
}

.row.divider > [class*='col-']:not(:last-child):after {
    background: $divider-color;
    width: 1px;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    min-height: 70px;
}

// Hack for tab spacing
//.tab-content .tab-pane > div {
//    margin-top: 10px;
//}

.table {
    thead tr {

        th {
            position: relative;
            padding: 8px 20px 8px 8px;

            &:not(:last-child) {
                border-right: 1px solid $divider-color;
            }

            span {

                &.fa {
                    font-size: 16px;
                    font-weight: bold;
                    right: 5px;

                    &.fa-angle-up {
                        position: absolute;
                        color: $muted-accent-color;
                        top: 5px;

                        &.active {
                            color: black;
                        }
                    }

                    &.fa-angle-down {
                        position: absolute;
                        color: $muted-accent-color;
                        top: 18px;

                        &.active {
                            color: black;
                        }
                    }
                }
            }
        }
    }
}

.popover {
    max-width: 350px;
    width: 350px;

    .popover-content {
        padding: 0;
    }

    &.research-access {
        max-width: 800px;
        width: inherit;
    }

}

.label-stock-vote-target {
    background-color: royalblue;
}

.label-stock-focus-list {
    background-color: crimson;
}

.label-stock-not-interested {
    background-color: #fce45c;
    color: black;
}

.label-stock-holding {
    background-color: forestgreen;
}

.label-vote {
    color: forestgreen;
}

.label-wildcard {
    background-color: #fce45c;
}

.label-blue-matrix-yes {
    padding: .3em 0.6em 0.2em;
    text-shadow: #666 1px 1px 1px;
    background-color: #288a28;
    color: white;
    text-align: center;
}

.label-blue-matrix-no {
    padding: .3em 0.3em 0.2em;
    background-color: inherit;
    color: inherit;
    font-size: 100%;
    text-align: center;
}

.label-blue-matrix-decline {
    padding: .3em 0.6em 0.2em;
    text-shadow: #666 1px 1px 1px;
    background-color: #784745;
    color: white;
    text-align: center;
}

.label-interest-category-L {
    padding: .3em 0.6em 0.2em;
    text-shadow: #666 1px 1px 1px;
    background-color: #784745;
    color: white;
    text-align: center;
}

.label-interest-category-Y {
    padding: .3em 0.6em 0.2em;
    text-shadow: #666 1px 1px 1px;
    background-color: #335cff;
    color: white;
    text-align: center;
}

.label-interest-category-F {
    padding: .3em 0.6em 0.2em;
    text-shadow: #666 1px 1px 1px;
    background-color: #288a28;
    color: white;
    text-align: center;
}

.label-interest-category-N {
    padding: .3em 0.6em 0.2em;
    background-color: #fce45c;
    color: black;
    text-align: center;
}

.label-interest-category-other {
    padding: .3em 0.6em 0.2em;
    background-color: inherit;
    color: inherit;
    font-size: 100%;
    text-align: center;
}

.label-meeting-author-research {
    background-color: #784745;
    color: white;
    font-weight: normal;
    font-size: 100%;
}

.label-meeting-author-traders {
    background-color: #337ab7;
    color: white;
    font-weight: normal;
    font-size: 100%;
}

.label-meeting-author-desk-analyst {
    background-color: #784745;
    color: white;
    font-weight: normal;
    font-size: 100%;
}

.label-meeting-author-desktop {
    padding: 2px 5px 3px 5px;
    min-width: 4em;
    display: inline-block;
}

.label-meeting-author-conf {
    background-color: #fce45c;
    color: black;
    font-weight: normal;
    font-size: 100%;
}

.label-activity-author-default {
    background-color: transparent;
    color: black;
    font-weight: normal;
    font-size: 100%;
}

.label-icon {
    font-size: 10px;
    padding: 0.1em 0.15em;
    border-radius: 10px;
}

.label-filter {
    font-weight: normal;
    font-size: 150%;
}

.squeeze {
    padding: 1px !important;
}

.center {
    text-align: center;
    vertical-align: middle !important;
}

.left {
    text-align: left;
}

.favorite-contact {
    color: #428bca;
}

@-webkit-keyframes colors {
    0% {
        color: red;
    }

    25% {
        color: yellow;
    }

    50% {
        color: blue;
    }

    100% {
        color: green;
    }
}

@-moz-keyframes colors {
    0% {
        color: red;
    }

    25% {
        color: yellow;
    }

    50% {
        color: blue;
    }

    100% {
        color: green;
    }
}

@keyframes colors {
    0% {
        color: red;
    }

    25% {
        color: yellow;
    }

    50% {
        color: blue;
    }

    100% {
        color: green;
    }
}

.annoying-animation {
    -moz-animation: colors;
    -o-animation: colors;
    -webkit-animation: colors;
    animation: colors;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    margin: 0 auto;
    text-align: center;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: #3b538a;
    border-color: #3b538a;
}

.dont-do-that > div {
    margin-bottom: 0;

    &:last-child {
        padding-right: 0;
    }
}

.inst-nav-brand {
    color: #337ab7 !important;
    text-align: center;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    overflow: hidden;
}

#toast-container.toast-bottom-center {
    & > div {
        width: 96%;
        margin: 0 auto 6px auto;
    }
}

.mobile-activity-icon {
    color: brown !important;
}

.mobile-contacts-icon {
    color: dodgerblue !important;
}

@media (max-width: 2000px) {
    .navbar-header {
        float: none;
    }

    .navbar-left, .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
    }

    .navbar-nav > li {
        float: none;
    }

    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collapse.in {
        display: block !important;
    }
}

.sk-wave {
    .sk-rect {
        background-color: #3b538a;
    }
}

.ellipsis {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.filter-display-ellipse {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.panel-filter {
    border-color: #585858;
}

.panel-filter > .panel-heading {
    color: #fff;
    background-color: #585858;
    border-color: #585858;
}

.panel-filter > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #585858;
}

.panel-filter > .panel-heading .badge {
    color: black;
    background-color: #fff;
}

.panel-filter > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #585858;
}

.overlay-panel-right {
    height: auto !important;
    overflow: visible !important;
    position: absolute !important;
    right: 15px !important;
    z-index: 500;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.overlay-panel-right > .panel {
    margin-bottom: 0 !important;
    border-radius: 3px;
}

/* Flag Indicator */

$default-flag-color: #ddd;
$notice-flag-color: #f6b237;
$delete-flag-color: #47619c;
$info-flag-color: #4c4d4c;
$nocontact-flag-color: #a94442;
$nocontact-background-color: #f2dede;
$nocontact-readonly-background-color: #ffe0c3;
$nocontact-readyonly-flag-color: darken($nocontact-readonly-background-color, 50%);

.table > thead > tr.flag,
.table > tbody > tr.flag,
.table > tfoot > tr.flag,
.table > thead > tr.flag,
.table > tbody > tr.flag,
.table > tfoot > tr.flag,
.cs-list-section.flag {
    border-left-width: 5px;
    border-left-style: solid;
}

.table > thead > tr.flag.flag-default,
.table > tbody > tr.flag.flag-default,
.table > tfoot > tr.flag.flag-default,
.table > thead > tr.flag.flag-default,
.table > tbody > tr.flag.flag-default,
.table > tfoot > tr.flag.flag-default,
.cs-list-section.flag.flag-default {
    border-left-color: $default-flag-color;
}

.table > thead > tr.flag.flag-notice,
.table > tbody > tr.flag.flag-notice,
.table > tfoot > tr.flag.flag-notice,
.table > thead > tr.flag.flag-notice,
.table > tbody > tr.flag.flag-notice,
.table > tfoot > tr.flag.flag-notice,
.cs-list-section.flag.flag-notice {
    border-left-color: $notice-flag-color;
}

.table > thead > tr.flag.flag-info,
.table > tbody > tr.flag.flag-info,
.table > tfoot > tr.flag.flag-info,
.table > thead > tr.flag.flag-info,
.table > tbody > tr.flag.flag-info,
.table > tfoot > tr.flag.flag-info,
.cs-list-section.flag.flag-info {
    border-left-color: $info-flag-color;
}

.table > tbody > tr.flag.flag-inactive {
    // background-color: #fcfcfc;
    // color: #aaaaaa;
    border-left-color: $delete-flag-color;
}

.table > tbody > tr.flag.flag-nocontact {
    border-left-color: $nocontact-flag-color;
    background-color: $nocontact-background-color;

    &.flag-nocontact-readonly {
        border-left-color: $nocontact-readyonly-flag-color;
        background-color: $nocontact-readonly-background-color;
    }
}

.alert-readonly {
    border-color: darken($nocontact-readonly-background-color, 10%);
    background-color: $nocontact-readonly-background-color;
    color: $nocontact-readyonly-flag-color;
}

.indicator.flag {
    min-height: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.mobile > .indicator.flag {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.indicator.flag.flag-default {
    background-color: $default-flag-color;
}

.indicator.flag.flag-notice {
    background-color: $notice-flag-color;
}

.table>tbody+tbody {
    border: 1px;
}

.number-alignment {
    text-align: right;
}

.nav-tabs > li > a {
    padding: 5px 10px 5px 10px;
}
.nav-tabs > li.active > a {
    padding: 5px 10px 5px 10px;
}

.inactiveButtonInherit {
    background-color: inherit;
}

.bold {
    font-weight: bold;
}

.boldish {
    font-weight: 500;
}

.pointer {
    cursor: pointer;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.input-group-btn:first-child:not(:last-child) > .btn {
    border-right: 0;
}


.in-the-red {
    color: red;
}

.in-the-black {
    color: green;
}

.neutral {
    color: transparent;
}

.custom-modal {

    @media (min-width: 930px){

        .modal-lg {
            width: 930px;
        }
    }

}

.full-width-dropdown {
    .ng-dropdown-panel {
        width: 100% !important;
    }
}

/* The default text color for disabled items is a bit too unnoticeable */
select option:disabled {
    color: #cbcbcb;
}

textarea.no-resize {
    resize: none;
}

.notifications-icon {
    position: relative;
    color: #778899;
    cursor: pointer;

    &.unseen-notifications::after {
        position: absolute;
        right: -3px;
        top: -2px;
        content: attr(data-count);
        font-size: 30%;
        border-radius: 999px;
        color: #fff;
        background: rgba(255,0,0,.85);
        text-align: center;
        font-weight: 700;
        height: 10px;
        width: 10px;
    }
}

.summary-filters {
  text-align: right;

  .form-group:not(:last-child) {
    margin-right: 5px;
  }
}

.summary-grid {
  padding-top: 5px;

  .ag-row {
    .leaf-node {
      background-color: #f5f5f5;
    }

    &:hover {
      .leaf-node {
        background-color: #e8e8e8;
      }
    }
  }
}
