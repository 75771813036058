@import "@ng-select/ng-select/themes/default.theme.css";
@import "./variables";

.ng-select {

  font-size: 12px;
  text-align: left;

  &.ng-invalid {
    .ng-select-container {
      border-color: red;
    }
  }

  &.align-right {
    .ng-dropdown-panel {
      left: auto;
      right: 0;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: inherit;
      min-height: 30px;

      .ng-value-container {

        .ng-placeholder {
          color: #000;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {

      min-height: 30px;
      max-height: 120px;
      overflow-y: auto;
      flex-wrap: wrap;

      .ng-value-container {
        padding-top: 2px;

        .ng-placeholder {
          color: #000;
        }

        .ng-value {
          background-color: $bootstrap-blue !important;
          border: 1px solid $bootstrap-darker-blue !important;
          border-radius: 4px;
          color: #fff;
          font-size: 1em;
          margin-bottom: 2px;

          .ng-value-icon {
            color: #000;
          }

          .ng-value-icon:hover {
            background-color: $bootstrap-blue;
          }

          span.uncovered {
            background-color: #d9534f !important;
          }

          span.existing {
            background-color: #f6b237 !important;
          }
        }
      }
    }
  }

  &[ng-reflect-searchable="false"] {
    .ng-placeholder {
      position: static !important;
      padding: 0 !important;
    }
  }

  .ng-clear-wrapper {
    display: none;
  }
}

.ng-dropdown-panel {

  width: inherit !important;
  font-size: 12px;

  .ng-dropdown-header {
    min-width: 150px;
    padding: 0;
  }

  .ng-optgroup {
    > input[type=checkbox] {
      margin-right: 8px;
      position: relative;
      top: 2px;
    }
  }

  .ng-option {

    > input[type=checkbox] {
      margin-right: 8px;
      position: relative;
      top: 2px;
    }

    &.ng-option-marked:not(.ng-option-selected) {
      background-color: #f5f5f5;
    }
  }
}
